import {
  HttpContext,
  HttpContextToken,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';

export interface ContextOptions {
  withoutToastError?: boolean;
  auth?: {
    username: string;
    password: string;
  };
  context?: HttpContext;
  returnSource?: Observable<HttpResponse<any>>;
}

export const API_PTS_CONTEXT = new HttpContextToken<ContextOptions>(() => ({}));
