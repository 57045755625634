import { Client } from '@core/api';

export interface ClientDB {
  name: string;
  qr_code: string | null;
  rfid_tag: string | null;
  offline_one_time_fueling_limit: number;
  type: Client.TypeEnum | null;
  uuid?: string | null;
  created?: number;
  updated?: number;
}

export enum searchClientTypesEnum {
  'qr_code' = 'qr_code',
  'rfid_tag' = 'rfid_tag'
}

// export type searchClientTypes = 'CODE' | 'RFID';
export enum searchClientTypes {
  CODE = 'CODE',
  RFID = 'RFID'
}

export interface SearchClientParams {
  type?: searchClientTypesEnum | null;
  search?: string | null;
}

export const testClientsData: ClientDB[] = [
  {
    name: 'TestDriver',
    qr_code: '1234',
    rfid_tag: null,
    offline_one_time_fueling_limit: 9999,
    type: Client.TypeEnum.Driver
  },
  {
    name: 'TestVehicle',
    qr_code: '5678',
    rfid_tag: null,
    offline_one_time_fueling_limit: 9999,
    type: Client.TypeEnum.Vehicle
  }
];
