import { inject, Injectable } from '@angular/core';
import { BaseDatabaseService } from '@core/services/database/base-database.service';
import { environment } from '@env';
import { isPlatform } from '@ionic/angular';
import {
  prepareINSERT,
  prepareUPDATE,
  prepareVALUES
} from '@core/services/database/models/transaction.interface';
import { v4 as uuid } from 'uuid';
import { ClientDB, SearchClientParams, testClientsData } from '@core/services/database/models/сlient.interface';
import { LoggerService } from '@core/services/logger/logger';

@Injectable()
export class ClientsService extends BaseDatabaseService {

  private loggerService = inject(LoggerService);

  /**
   * Delete all Clients and setup test data
   * @returns
   */
  async clearAndAddTestData(): Promise<boolean> {
    const res = await this.deleteClients();
    if (res) {
      for (const testClient of testClientsData) {
        await this.createClient(testClient);
      }
    }
    return true;
  }

  /**
   * Get all Clients
   * @returns
   */
  async getClients(
    searchParams: SearchClientParams,
    offset: number = 10,
    limit: number
  ): Promise<ClientDB[]> {
    let order = `created DESC`;
    let query = 'SELECT * FROM clients';
    if (searchParams.type && searchParams.search) {
      switch (searchParams.type) {
        case 'code':
          query += ` WHERE code = "${searchParams.search}"`;
          break;
        case 'rfid_tag':
          query += ` WHERE rfid_tag = "${searchParams.search}"`;
      }
    }
    query += ` ORDER BY ${order}`;
    query += ` LIMIT ${offset}, ${limit}`;

    const values = await this.mDb.query(query);
    this.loggerService.info('Clients', 'getClients', `$sql: ${query}`);
    return values.values as ClientDB[];

    // return (await this.mDb.query(query)).values as Client[];
  }

  async getClientByCode(code: number): Promise<ClientDB | null> {
    const query = `SELECT * FROM clients WHERE code = ${code}`;

    if (!environment.production) {
      console.log('#SQLClientByCode', query);
    }

    let ret: any = await this.mDb.query(query);
    if (ret.values.length > 0) {
      return ret.values[0] as ClientDB;
    }
    return null;
  }

  async getClientByRFID(rfid_tag: string): Promise<ClientDB | null> {
    const query = `SELECT * FROM clients WHERE rfid_tag = "${rfid_tag}"`;

    if (!environment.production) {
      console.log('#SQLClientByRFID', query);
    }

    let ret: any = await this.mDb.query(query);
    if (ret.values.length > 0) {
      return ret.values[0] as ClientDB;
    }
    return null;
  }

  async getClient(id: number): Promise<ClientDB | null> {
    const query = `SELECT * FROM clients WHERE id = ${id}`;

    if (!environment.production) {
      console.log('#getClient', query);
    }

    let ret: any = await this.mDb.query(query);
    if (ret.values.length > 0) {
      return ret.values[0] as ClientDB;
    }
    return null;
  }

  /**
   * Create Client
   * @returns
   */
  async createClient(client: ClientDB): Promise<boolean> {
    client.created = new Date().getTime();
    client.uuid = uuid();

    const { sql, values } = prepareINSERT('clients', client);

    if (!environment.production) {
      console.log('#SQLCreateClient', sql);
    }

    try {
      await this.mDb.query(sql, prepareVALUES(values));
      if (!isPlatform('hybrid')) {
        await this.sqliteService.sqliteConnection.saveToStore(
          this.databaseName
        );
      }
      this.loggerService.info('Clients', 'createClient', `$sql: ${sql} ${JSON.stringify(values)}`);
      return true;
    } catch (e: any) {
      this.loggerService.error('Clients', 'createClient', e);
      return false;
    }
  }

  async updateClient(
    id: ClientDB['id'],
    client: Partial<ClientDB>
  ): Promise<boolean> {
    if (!id) {
      return false;
    }
    client.updated = new Date().getTime();
    const { sql, values } = prepareUPDATE('clients', client);
    try {
      await this.mDb.query(`${sql} WHERE id=?`, [...prepareVALUES(values), id]);
      if (!isPlatform('hybrid')) {
        await this.sqliteService.sqliteConnection.saveToStore(
          this.databaseName
        );
      }
      this.loggerService.info('Clients', 'updateClient', `$sql: ${sql} WHERE id=? ${JSON.stringify(values)}`);
      return true;
    } catch (e) {
      this.loggerService.error('Clients', 'updateClient', e);
      return false;
    }
  }

  /**
   * Get count Clients
   * @returns
   */
  async countClients() {
    let sqlcmd: string = 'SELECT COUNT(*) as count FROM clients';
    let ret: any = await this.mDb.query(sqlcmd);
    if (ret.values.length > 0) {
      return ret.values[0].count;
    }
    throw Error('count clients failed');
  }

  /**
   * Get Client by id
   * @returns
   */
  async getClientById(id: number): Promise<ClientDB | boolean> {
    let sqlcmd: string = 'SELECT * FROM clients WHERE id = ? LIMIT 1';
    let values: Array<any> = [id];
    let ret: any = await this.mDb.query(sqlcmd, values);
    if (ret.values.length > 0) {
      return ret.values[0] as ClientDB;
    }
    return false;
  }

  /**
   * Delete Client by id
   * @returns
   */
  async deleteClientById(id: number): Promise<boolean> {
    await this.mDb.query(`DELETE FROM clients WHERE id = ${id};`);
    if (!isPlatform('hybrid')) {
      await this.sqliteService.sqliteConnection.saveToStore(this.databaseName);
    }
    return true;
  }

  /**
   * Delete all Clients
   * @returns
   */
  async deleteClients(): Promise<boolean> {
    await this.mDb.query(`DELETE FROM clients`);
    if (!isPlatform('hybrid')) {
      await this.sqliteService.sqliteConnection.saveToStore(this.databaseName);
    }
    return true;
  }
}
