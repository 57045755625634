import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { PTS_DIGEST_INTERCEPTOR } from '@core/services/PTSClient/auth/Digest/PTSDigestAuth.interceptor';
import { CapacitorHttpInterceptor } from '@core/services/PTSClient/capacitor-http.interceptor';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    PTS_DIGEST_INTERCEPTOR,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CapacitorHttpInterceptor,
      multi: true
    }
  ]
})
export class PTSClientModule {}
