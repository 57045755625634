import { CommonModule } from '@angular/common';
import {
  APP_INITIALIZER,
  LOCALE_ID,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { StorageService } from '@core/services/storage/storage.service';
import { LanguagesService } from '@core/services/languages/languages.service';
import { AppStore } from '@core/store/app.store';
import { PTSClientModule } from '@core/services/PTSClient/PTSClient.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DevProxyInterceptor } from '@core/dev-proxy.interceptor';
// import { IonicStorageModule } from '@ionic/storage-angular';
// import { CONFIG_APP } from '@config-app';
// import { Drivers } from '@ionic/storage';

// import { AppStore } from '@core/store/app-store/app-store';

@NgModule({
  imports: [CommonModule, PTSClientModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: DevProxyInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          languages: LanguagesService,
          storage: StorageService,
          appStore: AppStore
        ) =>
        async () => {
          await storage.initialStorage();
          await appStore.initialize();
          await languages.initialAppLanguage();
        },
      multi: true,
      deps: [LanguagesService, StorageService, AppStore]
    },
    {
      provide: LOCALE_ID,
      useFactory: (language: LanguagesService) =>
        language.translate.currentLang || language.currentLanguage,
      deps: [LanguagesService]
    }
  ],
  declarations: [
    //  ErrorTextPipe
  ],
  exports: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    //   throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
