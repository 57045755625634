import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import {
  EVENT,
  EventAppObservable
} from '@core/services/events-app/events-app.interface';
import { filter, map, share, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventsInApp {
  private debugEvent = true;
  private observer!: Subscriber<EventAppObservable>;
  private channels$: Observable<EventAppObservable> =
    new Observable<EventAppObservable>((observer) => {
      this.observer = observer;
    }).pipe(
      tap((event) => {
        if (this.debugEvent) {
          logEvent(event);
        }
      }),
      share()
    );

  channelSubscribe<T = unknown>(topic: EVENT | EVENT[]): Observable<T> {
    return this.channels$.pipe(
      // @ts-ignore
      filter((e) => [].concat(topic).includes(e.topic)),
      map((e) => e.data as T)
    );
  }

  async publish(topic: EVENT, data = {}): Promise<void> {
    this.observer.next({
      topic,
      data
    });
    return Promise.resolve();
  }
}

const logEvent = (event: EventAppObservable) => {
  console.log(
    `%cEVENT: %c${EVENT[event.topic]}`,
    'color: yellow;',
    'color: orange;font-weight: bold;',
    event.data
  );
};
