import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { CONFIG_APP } from '@config-app';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StorageService } from '@core/services/storage/storage.service';
import { EventsInApp } from '@core/services/events-app/events-app.service';
import { EVENT } from '@core/services/events-app/events-app.interface';

export const STORAGE_LANGUAGE_KEY = 'STORAGE_LANGUAGE_KEY';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {
  public currentLanguage: string = CONFIG_APP.DEFAULT_LANGUAGE;
  public supportsLanguages = CONFIG_APP.SUPPORTS_LANGUAGES;

  private isInitialAppLanguage = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public translate: TranslateService,
    private events: EventsInApp,
    private storage: StorageService
  ) {}

  async initialAppLanguage() {
    if (this.isInitialAppLanguage) {
      return;
    }
    this.isInitialAppLanguage = true;

    this.translate.addLangs(Object.keys(CONFIG_APP.SUPPORTS_LANGUAGES));

    const currentLanguage =
      await this.storage.get<string>(STORAGE_LANGUAGE_KEY);

    if (currentLanguage) {
      this.setLanguage(currentLanguage);
    } else {
      this.setLanguageByDevice();
    }

    this.events
      .channelSubscribe(EVENT.LOGOUT_GUEST_MODE)
      .subscribe(async () => {
        await this.resetLanguage();
      });
  }

  setLanguage(
    setLang: string = CONFIG_APP.DEFAULT_LANGUAGE,
    isSave: boolean = false
  ) {
    if (setLang in this.supportsLanguages) {
      this.currentLanguage = setLang;

      this.translate.use(setLang).subscribe(async () => {
        this.document.documentElement.lang = setLang;
        if (isSave) {
          await this.events.publish(EVENT.LANGUAGE_CHANGE, setLang);
          await this.storage.set<string>(STORAGE_LANGUAGE_KEY, setLang);
        }
      });
    }
  }

  async resetLanguage() {
    const storageLanguage = await this.storage.get(STORAGE_LANGUAGE_KEY);
    this.setLanguage(storageLanguage);
  }

  private setLanguageByDevice() {
    const browserLang: string =
      this.translate.getBrowserLang() || CONFIG_APP.DEFAULT_LANGUAGE;
    console.log('LanguagesService browserLang', browserLang);
    const SUPPORTS_LANGUAGES: string =
      Object.keys(CONFIG_APP.SUPPORTS_LANGUAGES).join('|') || '';
    console.log('LanguagesService SUPPORTS_LANGUAGES', SUPPORTS_LANGUAGES);

    if (browserLang.match(SUPPORTS_LANGUAGES)) {
      this.setLanguage(browserLang);
    } else {
      this.setLanguage(CONFIG_APP.DEFAULT_LANGUAGE);
    }
  }
}

export const createTranslateLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json?');
