import { inject, Injectable } from '@angular/core';
import { SQLiteService } from './sqlite.service';
import { ClientsService } from '@core/services/database/client.service';
import { TransactionsService } from '@core/services/database/transactions.service';
import { isPlatform } from '@ionic/angular';
import { DbnameVersionService } from '@core/services/database/dbname-version.service';
import { capSQLiteVersionUpgrade } from '@capacitor-community/sqlite/src/definitions';
import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { dbVersionUpgrades } from '@core/services/database/upgrades/upgrade-db';

@Injectable()
export class InitializeAppService {
  isAppInit: boolean = false;
  protected sqliteService: SQLiteService = inject(SQLiteService);
  protected dbVerService: DbnameVersionService = inject(DbnameVersionService);
  public databaseName = 'prime';
  protected versionUpgrades: capSQLiteVersionUpgrade[] = [
    {
      toVersion: 1,
      statements: []
    }
  ];
  protected loadToVersion = 1;
  public mDb!: SQLiteDBConnection;


  //  platform!: string;

  constructor(
    private clientsService: ClientsService,
    private transactionsService: TransactionsService
  ) {
    this.versionUpgrades = dbVersionUpgrades;
    this.loadToVersion =
      dbVersionUpgrades[dbVersionUpgrades.length - 1].toVersion;
  }

  initializeApp() {
    return new Promise<void>(async (resolve, reject) => {
      await this.sqliteService.initializePlugin();
      // this.platform = this.sqliteService.platform;
      try {
        if (!isPlatform('hybrid')) {
          await this.sqliteService.initWebStore();
        }

        await this.sqliteService.addUpgradeStatement({
          database: this.databaseName,
          upgrade: this.versionUpgrades
        });

        if (
          this.sqliteService.native &&
          (await this.sqliteService.isInConfigEncryption()).result &&
          (await this.sqliteService.isDatabaseEncrypted(this.databaseName)).result
        ) {
          this.mDb = await this.sqliteService.openDatabase(
            this.databaseName,
            true,
            'secret',
            this.loadToVersion,
            false
          );
        } else {
          this.mDb = await this.sqliteService.openDatabase(
            this.databaseName,
            false,
            'no-encryption',
            this.loadToVersion,
            false
          );
        }

        await this.clientsService.initializeDatabase(this.databaseName, this.loadToVersion, this.mDb);
        await this.transactionsService.initializeDatabase(this.databaseName, this.loadToVersion, this.mDb);

        this.dbVerService.set(this.databaseName, this.loadToVersion);

        this.isAppInit = true;
      } catch (error) {
        console.log(`initializeAppError: ${error}`);
      }

      resolve();
    });
  }

}
