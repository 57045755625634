import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule
} from '@angular/core';
import { InitializeAppService } from '@core/services/database/initialize.app.service';
import { DbnameVersionService } from '@core/services/database/dbname-version.service';
import { ClientsService } from '@core/services/database/client.service';
import { SQLiteService } from '@core/services/database/sqlite.service';
import { TransactionsService } from '@core/services/database/transactions.service';

export function initializeFactory(init: InitializeAppService) {
  return () => init.initializeApp();
}

@NgModule({
  imports: [],
  providers: [
    SQLiteService,
    InitializeAppService,
    ClientsService,
    TransactionsService,
    DbnameVersionService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeFactory,
      deps: [InitializeAppService],
      multi: true
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DatabaseModule {}
