import { Injectable } from '@angular/core';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { AlertOptions, ToastOptions } from '@ionic/core';
import { LanguagesService } from '@core/services/languages/languages.service';

export enum TypeSystemMessage {
  ALERT,
  TOAST
}

export type HTMLTypeSystemMessage = HTMLIonAlertElement | HTMLIonToastElement;

@Injectable({
  providedIn: 'root'
})
export class SystemMessagesService {
  private showing: false | HTMLTypeSystemMessage = false;
  private queue: HTMLTypeSystemMessage[] = [];

  constructor(
    public platform: Platform,
    private languages: LanguagesService,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController
  ) {}

  async create(
    opts?: AlertOptions | ToastOptions,
    typeMessage?: TypeSystemMessage
  ): Promise<HTMLTypeSystemMessage> {
    if (typeMessage === TypeSystemMessage.ALERT) {
      return this.alertCtrl.create(opts as AlertOptions);
    }
    return this.toastCtrl.create(opts as ToastOptions);
  }

  async showMessage(
    opts: AlertOptions | ToastOptions,
    typeMessage: TypeSystemMessage = TypeSystemMessage.TOAST,
    isIgnoreQueue = false
  ) {
    await this.platform.ready();

    // if (type === TypeSystemMessage.TOAST && isPlatform('desktop')) {
    //   opts = {position: 'top', ...opts};
    // }
    const INSTANCE = await this.create(opts, typeMessage);
    const dismissed = () => {
      const index = this.queue.indexOf(INSTANCE);
      if (index !== -1) {
        this.queue.splice(this.queue.indexOf(INSTANCE), 1);
      } else {
        this.next();
      }
    };
    if (isIgnoreQueue) {
      await INSTANCE.present();
      return INSTANCE;
    }

    INSTANCE.onWillDismiss().then(dismissed);
    if (this.showing) {
      // const origDismiss = INSTANCE.dismiss.bind(alert);
      //  INSTANCE.dismiss = (data, role, options) => {
      //    dismissed();
      //    //return origDismiss(data, role, options);
      //  };
      this.queue.push(INSTANCE);
    } else {
      INSTANCE.onWillDismiss().then(() => {
        // dismissed();
      });
      INSTANCE.present().then(() => {
        console.log('INSTANCE.present');
        // INSTANCE.onWillDismiss(dismissed);
        // const origDismiss = overlay.dismiss.bind(alert);
        // overlay.dismiss = (data, role, options) => {
        //   dismissed();
        //   return origDismiss(data, role, options);
        // };
      });
      this.showing = INSTANCE;
    }
    return INSTANCE;
  }

  async toastError(message: string, duration: number = 3000) {
    return await this.showMessage(
      {
        message: message
        //  duration
      },
      TypeSystemMessage.TOAST
    );
  }

  private async next() {
    const next = this.queue.shift();
    if (next) {
      return next.present();
    }
    this.showing = false;
  }

  async cleanQueue() {
    for await (const item of this.queue || []) {
      await item.dismiss();
    }
    this.queue = [];
    if (this.showing) {
      await this.showing.dismiss();
    }
  }
}
