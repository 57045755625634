import {
  enableProdMode,
  ErrorHandler,
  importProvidersFrom,
  NgModule
} from '@angular/core';
import {
  bootstrapApplication,
  enableDebugTools
} from '@angular/platform-browser';
import { provideRouter, RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import * as Sentry from '@sentry/angular-ivy';
import { routes } from '@app/app.routes';
import { AppComponent } from '@app/app.component';
import { environment } from '@env';
import { registerLocaleData } from '@angular/common';
import localeAz from '@angular/common/locales/az';
import localeHy from '@angular/common/locales/hy';
import localePl from '@angular/common/locales/pl';
import localeRu from '@angular/common/locales/ru';
import localeSk from '@angular/common/locales/sk';
import localeUk from '@angular/common/locales/uk';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { CoreModule } from '@core/core.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from '@core/services/languages/languages.service';
import { IonicStorageModule } from '@ionic/storage-angular';
import { CONFIG_APP } from '@config-app';
import { Drivers } from '@ionic/storage';
import { defineCustomElements as pwaElements } from '@ionic/pwa-elements/loader';
import { defineCustomElements as jeepSqlite } from 'jeep-sqlite/loader';
import { Capacitor } from '@capacitor/core';
import { CapacitorSQLite, SQLiteConnection } from '@capacitor-community/sqlite';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { DatabaseModule } from '@core/services/database/database.module';

registerLocaleData(localeUk, 'uk');
registerLocaleData(localeRu, 'ru');
registerLocaleData(localePl, 'pl');
registerLocaleData(localeSk, 'sk');
registerLocaleData(localeAz, 'az');
registerLocaleData(localeHy, 'hy');

if (environment.production) {
  enableProdMode();
}

const platform = Capacitor.getPlatform();
if (platform === 'web') {
  // Web platform
  // required for toast component in Browser
  pwaElements(window);

  // required for jeep-sqlite Stencil component
  // to use a SQLite database in Browser
  jeepSqlite(window);

  window.addEventListener('DOMContentLoaded', async () => {
    const jeepEl = document.createElement('jeep-sqlite');
    document.body.appendChild(jeepEl);
    jeepEl.autoSave = false;
  });
}

Sentry.init({
  dsn: 'https://fb3510097d3a4a9fa6acff3cc42651ec@o375951.ingest.sentry.io/4505256669413376',
  environment: `${environment.production ? 'prod' : 'dev'}`,
  enabled: environment.production,
  // enabled: environment.production,
  integrations: [
    // new Sentry.BrowserTracing({
    //   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    //   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    //   routingInstrumentation: Sentry.routingInstrumentation
    // }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

@NgModule({
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [routes]
    }
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true
    // }
  ]
})
class SentryModule {}

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    importProvidersFrom(SentryModule),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
        }
      })
    ),
    importProvidersFrom(
      IonicStorageModule.forRoot({
        name: '__' + CONFIG_APP.PACKAGE_NAME.replace(/\./g, '_'),
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
      })
    ),
    importProvidersFrom(
      IonicModule.forRoot({
        innerHTMLTemplatesEnabled: true,
        mode: 'md'
      })
    ),
    importProvidersFrom(CoreModule),
    importProvidersFrom(DatabaseModule),
    provideRouter(routes)
  ]
})
  .then((ApplicationRef) => {
    console.log(`Bootstrap success`);
    if (!environment.production) {
      // allows to run `ng.profiler.timeChangeDetection();`
      enableDebugTools(ApplicationRef.components[0]);
    }
  })
  .catch((err) => console.log(err));
