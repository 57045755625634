import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject } from 'rxjs';

interface CacheStorageRecord<T = any> {
  expires: number;
  value: T;
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public init$ = new BehaviorSubject(false);
  private storage!: Storage;

  constructor(private storageProvider: Storage) {}

  async initialStorage(): Promise<boolean> {
    if (this.init$.getValue()) {
      return true;
    }
    this.storage = await this.storageProvider.create();
    this.init$.next(true);
    return true;
  }

  public set<T = any>(
    key: string,
    value: T,
    ttl: number | null = null
  ): Promise<void> {
    if (ttl === null) {
      return this.storage?.set(key, value) || Promise.resolve();
    }

    return (
      this.storage?.set(key, {
        expires: new Date().setMilliseconds(ttl),
        value
      }) || Promise.resolve()
    );
  }

  public async get<T = any>(key: string): Promise<T | null> {
    const value = await this.storage?.get(key);

    if (value === undefined || value === null) {
      return null;
    }

    if (
      typeof value === 'object' &&
      'expires' in value &&
      (value as CacheStorageRecord).expires !== null
    ) {
      const validInStorage =
        (value as CacheStorageRecord)?.expires > new Date().getTime();
      if (!validInStorage) {
        await this.storage?.remove(key);
        return null;
      }
      return (value as CacheStorageRecord<T>).value;
    }

    return value;
  }

  public remove(key: string) {
    return this.storage?.remove(key);
  }
}

//
// export const STORAGE_INITIALIZER = [
//   {
//     provide: APP_INITIALIZER,
//     useFactory: (storage: StorageService) => () => storage.initialStorage(),
//     deps: [StorageService],
//     multi: true
//   }
// ];
