import { inject, Injectable } from '@angular/core';
import {
  prepareINSERT,
  prepareUPDATE,
  prepareVALUES,
  TransactionDB
} from '@core/services/database/models/transaction.interface';
import { BaseDatabaseService } from '@core/services/database/base-database.service';
import { LoggerService } from '@core/services/logger/logger';
import { isPlatform } from '@ionic/angular';
import { v4 as uuid } from 'uuid';

export interface SQLFilterTransactions {
  page?: number;
  limit?: number;
  createdFrom?: number | string;
  createdTo?: number | string;
  isTracking?: boolean;
  pump?: number;
  nozzle?: number;
  driver?: string;
  vehicle?: string;
  status?: 'finished' | 'error';
  sort?:
    | 'created'
    | 'updated'
    | 'transactionId'
    | 'pump'
    | 'nozzle'
    | 'fuelGrade'
    | 'transactionState'
    | 'filledVolume';
  order?: 'ASC' | 'DESC';
}

@Injectable()
export class TransactionsService extends BaseDatabaseService {

  private loggerService = inject(LoggerService)

  constructor() //override dbVerService: DbnameVersionService //override sqliteService: SQLiteService,
  {
    super(/*sqliteService, dbVerService*/);
    this.exec = async () => {
      const query = `SELECT uuid FROM transactions WHERE uuid IS NULL LIMIT 1`;
      const res = await this.mDb.query(query);
      const isUUID = !!res?.values?.length;
      if (!isUUID) {
        // Set uuid for all transactions
        const query = `SELECT id FROM transactions WHERE uuid IS NULL`;
        const res = await this.mDb.query(query);
        console.log('res', res);
        if (res?.values?.length) {
          for (const item of res.values) {
            const { sql, values } = prepareUPDATE('transactions', {
              uuid: uuid()
            });
            await this.mDb.query(`${sql} WHERE id=?`, [
              ...prepareVALUES(values),
              item.id
            ]);
          }
        }
      }

      console.log('isUUID', isUUID);
    };
  }

  async addTransaction(transaction: Partial<TransactionDB>) {
    transaction.uuid = uuid();
    transaction.created = new Date().getTime();
    console.log('addTransaction', transaction);
    const { sql, values } = prepareINSERT('transactions', transaction);
    try {
      console.log('addTransaction', prepareVALUES(values));
      await this.mDb.query(sql, prepareVALUES(values));
      if (!isPlatform('hybrid')) {
        await this.sqliteService.sqliteConnection.saveToStore(
          this.databaseName
        );
      }
      this.loggerService.info('Add transaction', 'addTransaction', `$sql: ${JSON.stringify(sql)}; values: ${JSON.stringify(values)} `);
      return true;
    } catch (e) {
      this.loggerService.error('Add transaction', 'addTransaction', e);
      throw Error('Error adding transaction');
    }
  }

  async updateTransaction(
    transactionId: TransactionDB['transactionId'],
    pump: TransactionDB['pump'],
    transaction: Partial<TransactionDB>
  ): Promise<boolean> {
    if (!transactionId || !pump) {
      return false;
    }

    transaction.updated = new Date().getTime();
    if (!transaction.sync_api) {
      // TODO  transaction.sync_api = 0;
    }

    const { sql, values } = prepareUPDATE('transactions', transaction);
    try {
      console.log(`${sql} WHERE transactionId=? AND pump=?`, [
        ...prepareVALUES(values),
        transactionId,
        pump
      ]);
      await this.mDb.query(`${sql} WHERE transactionId=? AND pump=?`, [
        ...prepareVALUES(values),
        transactionId,
        pump
      ]);
      if (!isPlatform('hybrid')) {
        await this.sqliteService.sqliteConnection.saveToStore(
          this.databaseName
        );
      }
      this.loggerService.info('Update transaction', 'updateTransaction', `$sql: ${JSON.stringify(sql)}; values: ${JSON.stringify(values)} `);
      return true;
    } catch (e) {
      this.loggerService.error('Update transaction', 'updateTransaction', e);
      return false;
    }
  }

  async truncateTransactions(): Promise<boolean> {
    let query = 'DELETE FROM transactions';
    await this.mDb.query(query);
    if (!isPlatform('hybrid')) {
      await this.sqliteService.sqliteConnection.saveToStore(this.databaseName);
    }
    return true;
  }

  /**
   * Get all Transactions
   * @returns
   */
  async getTransactionsOLD(
    offset: number = 10,
    limit: number
  ): Promise<TransactionDB[]> {
    let query = `SELECT *
                 FROM transactions
                 LIMIT ${offset}, ${limit}`;
    return (await this.mDb.query(query)).values as TransactionDB[];
  }

  async getWatchTransactions(): Promise<TransactionDB[]> {
    return this.getTransactions({ isTracking: true });
  }

  getExportTransactions(
    params: SQLFilterTransactions = {}
  ): Promise<TransactionDB[]> {
    return this.#SQLTransactions<TransactionDB[]>(
      params,
      false,
      true
    ) as Promise<TransactionDB[]>;
  }

  getTransactions(
    params: SQLFilterTransactions = {}
  ): Promise<TransactionDB[]> {
    return this.#SQLTransactions<TransactionDB[]>(params) as Promise<
      TransactionDB[]
    >;
  }

  getTransactionsCount(params: SQLFilterTransactions = {}): Promise<number> {
    return this.#SQLTransactions<number>(params, true);
  }

  async #SQLTransactions<T = TransactionDB[]>(
    params: SQLFilterTransactions = {},
    selectCount: boolean = false,
    forExport = false
  ): Promise<T> {
    params = { ...params };
    let page = 1;
    let limit = 100;
    let order = `${params.sort || 'created'} ${params.order || 'DESC'}`;

    if (params.limit) {
      limit = Math.max(1, params.limit);
    }

    if (params.page) {
      page = Math.max(1, params.page);
    }

    let sql_limit = !forExport ? ` LIMIT ${(page - 1) * limit}, ${limit}` : '';

    let isTrackingFilter = '';
    if (typeof params.isTracking === 'boolean') {
      isTrackingFilter = ` AND isTracking = ${params.isTracking ? 1 : 0}`;
    }

    let pumpFilter = '';
    if (params.pump) {
      pumpFilter = ` AND pump = ${Number(params.pump)}`;
    }

    let nozzleFilter = '';
    if (params.nozzle) {
      nozzleFilter = ` AND nozzle = ${Number(params.nozzle)}`;
    }

    let statusFilter = '';
    if (params.status) {
      switch (params.status) {
        case 'error':
          statusFilter = `AND transactionState != 'Finished' AND transactionState != 'Filling'`;
          break;
        case 'finished':
          statusFilter = `AND transactionErrorMessage IS NULL AND transactionState = 'Finished'`;
          break;
      }
    }

    let driver = '';
    if (params.driver) {
      let driverSearch = `driver LIKE '%"rfid_tag":"${params.driver}"%'`;
      const code = Number(params.driver);
      if (!isNaN(code)) {
        driverSearch += ` OR driver LIKE '%"code":${code}%'`;
      }
      driver = `AND ( ${driverSearch} )`;
    }

    let vehicle = '';
    if (params.vehicle) {
      let vehicleSearch = `vehicle LIKE '%"rfid_tag":"${params.vehicle}"%'`;
      const code = Number(params.vehicle);
      if (!isNaN(code)) {
        vehicleSearch += ` OR vehicle LIKE '%"code":${code}%'`;
      }
      driver = `AND ( ${vehicleSearch} )`;
    }

    let createdFromFilter = '';
    if (params.createdFrom) {
      try {
        if (typeof params.createdFrom === 'string') {
          params.createdFrom = new Date(params.createdFrom).getTime();
        }
        createdFromFilter = ` AND created >= ${Number(params.createdFrom)}`;
      } catch (e) {
        this.loggerService.error('Transactions', '#SQLTransactions', e);
      }
    }

    let createdToFilter = '';
    if (params.createdTo) {
      try {
        if (typeof params.createdTo === 'string') {
          params.createdTo = new Date(params.createdTo).getTime();
        }
        createdToFilter = ` AND created <= ${Number(params.createdTo)}`;
      } catch (e) {
        this.loggerService.error('Transactions', '#SQLTransactions', e);
      }
    }

    let query = '';
    if (selectCount) {
      query = `SELECT COUNT(*) as count`;
    } else {
      query = `SELECT *`;
    }

    // add from
    query += ` FROM transactions WHERE 1 `;

    // add filters
    query += ` ${isTrackingFilter} ${pumpFilter} ${nozzleFilter} ${statusFilter} ${createdFromFilter} ${createdToFilter} ${driver} ${vehicle}`;

    if (!selectCount) {
      // add order and limit
      query += ` ORDER BY ${order} ${sql_limit}`;
    }

    this.loggerService.info('Transactions', '#SQLTransactions', query);

    let res = await this.mDb.query(query);

    if (selectCount) {
      if (res?.values?.length) {
        return res.values[0].count as T;
      }
      return 0 as T;
    }

    return res.values as T;
  }

  /**
   * Delete all Transactions
   * @returns
   */
  async deleteTransactions(): Promise<boolean> {
    await this.mDb.query(`DELETE FROM transactions`);
    if (!isPlatform('hybrid')) {
      await this.sqliteService.sqliteConnection.saveToStore(this.databaseName);
    }
    return true;
  }
}
