import { Component, inject, OnInit } from '@angular/core';
import { IonicModule, NavController } from '@ionic/angular';
import { EventsInApp } from '@core/services/events-app/events-app.service';
import { EVENT } from '@core/services/events-app/events-app.interface';
import {
  SystemMessagesService,
  TypeSystemMessage
} from '@core/services/system-messages/system-messages';
import { LanguagesService } from '@core/services/languages/languages.service';
import { Router } from '@angular/router';
import { AppStore } from '@core/store/app.store';
import { ClientsService } from '@core/services/database/client.service';
import { environment } from '@env';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule]
})
export class AppComponent implements OnInit {
  private readonly eventsInApp = inject(EventsInApp);
  private readonly systemMessages = inject(SystemMessagesService);
  private readonly clientsService = inject(ClientsService);
  private readonly languages = inject(LanguagesService);
  private readonly navCtrl = inject(NavController);
  private readonly router = inject(Router);
  private readonly appStore = inject(AppStore);

  constructor() {
    console.log(1111111, JSON.stringify(environment))
    this.#initializeApp();
  }

  #initializeApp() {
    this.eventsInApp
      .channelSubscribe(EVENT.CONFIRM_LOGOUT_GUEST_MODE)
      .subscribe(async (res) => {
        await this.#confirmExitGuestMode();
      });
    this.eventsInApp
      .channelSubscribe(EVENT.CONFIRM_LOGOUT_AND_REDIRECT_TO_TRANSACTIONS)
      .subscribe(async (res) => {
        await this.#confirmExitGuestMode('/transactions', false);
      });
  }

  async #confirmExitGuestMode(navigateTo = 'settings', replaceUrl = true) {
    const LANG = this.languages.translate.instant([
      'CONFIRMATION',
      'CONFIRMATION_EXIT_GUEST_MODE_MESSAGE',
      'PASSWORD_PLACEHOLDER',
      'INVALID_PASSWORD',
      'CANCEL',
      'OK',
      'QUIT'
    ]);
    await this.systemMessages.showMessage(
      {
        header: LANG.CONFIRMATION,
        message: LANG.CONFIRMATION_EXIT_GUEST_MODE_MESSAGE,
        backdropDismiss: false,
        keyboardClose: false,
        inputs: [
          {
            type: 'password',
            name: 'unlock-password',
            placeholder: LANG.PASSWORD_PLACEHOLDER,
            attributes: {
              autocorrect: 'off',
              autocapitalize: 'off',
              spellcheck: 'false',
              inputmode: 'numeric',
              autocomplete: 'off',
              autofocus: false
            }
          }
        ],
        buttons: [
          {
            text: LANG.CANCEL,
            role: 'cancel',
            handler: () => {}
          },
          {
            text: LANG.QUIT,
            role: 'destructive',
            handler: async (value) => {
              if (
                Number(value['unlock-password']) ===
                Number(this.appStore.getState('appSettings')?.passwordGuestMode)
              ) {
                this.appStore.patchState({
                  isGuestMode: false
                });
                this.navCtrl.setDirection('back');
                return await this.router.navigate([navigateTo], {
                  replaceUrl: replaceUrl
                });
              } else {
                return await this.systemMessages.showMessage({
                  header: LANG.INVALID_PASSWORD,
                  message: '',
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: LANG.OK,
                      role: 'cancel',
                      handler: () => {
                        this.#confirmExitGuestMode();
                      }
                    }
                  ]
                });
              }
            }
          }
        ]
      },
      TypeSystemMessage.ALERT
    );
  }

  async ngOnInit() {
    if (this.appStore.getState('firstTimeLoad')) {
      await this.clientsService.clearAndAddTestData();
      this.appStore.patchState({
        firstTimeLoad: false
      });
    }
  }
}
