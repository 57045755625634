import { PTS } from '@core/services/PTSClient/types/PTS';
import { ClientDB } from '@core/services/database/models/сlient.interface';

export interface TransactionDB {
  //
  id?: number;
  // 331e3ade-2575-4320-9942-b40cb45c57d0 = 36
  uuid?: string | null;
  // TEXT =  JSON
  driver: string | null;
  // TEXT JSON; example "{"identifier":"Indolent Border","code":7056,"rfid_tag":"mk6i3j","fueling_limit":7213,"driver_vehicle":"vehicle"}"
  vehicle: string | null;

  // String|null: example "123456"
  odometer: string | null;
  // Number|null: example 2
  pump: number | null;
  // Number|null: example 9
  nozzle: number | null;
  /**
   * Название топлива
   */
  fuelGrade: string | null;
  // Number|null: example 82596
  transactionId?: number | null;

  transactionState?: string | null;
  // String|null: example "JSONPTS_ERROR_COULD_NOT_GET_PRICE_VALUE"
  transactionErrorMessage?: string | null;
  /**
   * Сколько залито по факту
   */
  filledVolume?: number | null;
  filledVolumeNoChangeCount?: number;
  /**
   * 0 | 1
   */
  isTracking: number;
  /**
   * Наша система вызывали команду PumpStop
   */
  isRequestPumpStop: number;
  //  Number|null: example 1704721938638
  created?: number;
  // Number|null; example 1704721938638
  updated?: number;

  // TEXT JSON; example "{"Pump":2,"Nozzle":9,"Type":"Volume","Dose":9533,"Price":0,"AutoCloseTransaction":true}"
  requestPumpAuthorize?: string | null;
  // TEXT JSON; example "{"Id":0,"Type":"PumpAuthorize","Error":true,"Code":38,"Message":"JSONPTS_ERROR_COULD_NOT_GET_PRICE_VALUE"}"
  responsePumpAuthorize?: string | null;
  transactionInformation?: string | null;

  /**
   * Field from app settings
   */
  siteName?: string | null;
  //
  sync_api?: number | null;
}

export type ExplodeTransactionDB = Omit<
  TransactionDB,
  'client' | 'requestPumpAuthorize'
> & {
  driver: ClientDB | null;
  vehicle: ClientDB | null;
  requestPumpAuthorize: PTS.Request[PTS.Commands.PumpAuthorize]['Data'] | null;
  responsePumpAuthorize:
    | PTS.Response[PTS.Commands.PumpAuthorize]['Data']
    | {
    // TODO
  }
    | null;
  transactionInformation:
    | PTS.Response[PTS.Commands.PumpGetTransactionInformation]['Data']
    | null;
};

export const ExplodeTransactionDB = (
  transaction: TransactionDB
): ExplodeTransactionDB => {
  const ExplodeTransactionDB: ExplodeTransactionDB = {
    ...transaction,
    ...{
      driver: null,
      vehicle: null,
      requestPumpAuthorize: null,
      responsePumpAuthorize: null,
      transactionInformation: null
    }
  };
  if (transaction.driver) {
    try {
      ExplodeTransactionDB.driver = JSON.parse(transaction.driver || '');
    } catch (error) {
      // ExplodeTransactionDB.driver = null;
    }
  }

  if (transaction.vehicle) {
    try {
      ExplodeTransactionDB.vehicle = JSON.parse(transaction.vehicle || '');
    } catch (error) {
      // ExplodeTransactionDB.vehicle = null;
    }
  }

  if (transaction.requestPumpAuthorize) {
    try {
      ExplodeTransactionDB.requestPumpAuthorize = JSON.parse(
        transaction.requestPumpAuthorize || ''
      );
    } catch (error) {
      ExplodeTransactionDB.requestPumpAuthorize = null;
    }
  }

  if (transaction.responsePumpAuthorize) {
    try {
      ExplodeTransactionDB.responsePumpAuthorize = JSON.parse(
        transaction.responsePumpAuthorize || ''
      );
    } catch (error) {
      ExplodeTransactionDB.responsePumpAuthorize = null;
    }
  } else {
    transaction.responsePumpAuthorize = null;
  }

  if (transaction.transactionInformation) {
    try {
      ExplodeTransactionDB.transactionInformation = JSON.parse(
        transaction.transactionInformation || ''
      );
    } catch (error) {
      ExplodeTransactionDB.transactionInformation = null;
    }
  }

  return ExplodeTransactionDB;
};

export const prepareINSERT = (
  tableName: string,
  values: object
): {
  sql: string;
  values: object;
} => {
  const fields = Object.keys(values);

  return {
    sql: `INSERT INTO ${tableName} (${fields.join(', ')})
          VALUES (${fields.map(() => '?').join(', ')})`,
    values: values
  };
};
export const prepareUPDATE = (
  tableName: string,
  values: object
): {
  sql: string;
  values: object;
} => {
  const fields = Object.keys(values);

  return {
    sql: `UPDATE ${tableName}
          SET ${fields.map((f) => `${f}=?`).join(', ')} `,
    values: values
  };
};
export const prepareVALUES = (values: object) => {
  return Object.values(values).map((value) => {
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    }
    return value;
  });
};
