import { Injectable } from '@angular/core';
import { environment } from '@env';

interface LogEntry {
  message: string;
  timestamp: number;
}

/**
 * Class of static methods to allow for consistent console logging.
 * @export
 */
@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  logs: LogEntry[] = [];

  constructor() {
    this.loadLogs();
  }

  public getBlobLogs() {
    const recentLogs = this.logs.map(log => new Date(log.timestamp).toLocaleString() + ': ' + log.message);
    const content = recentLogs.join('\n\n');
    return new Blob([content], { type: 'text/plain' });
  }

  /**
   * Logs a consistent debug message format to the console.
   * @param [message] Optional message to log. Accepts objects too. Avoid circular json object references!
   * @param [devOnly] Only logs in development if true.
   */
  public debug(
    module: string,
    method: string,
    message?: any,
    devOnly?: boolean
  ) {
    const formatedMessage = this.getMessage(module, method, message, devOnly);
    const timestamp = Date.now();
    this.keepLog([`Debug: ${formatedMessage}; `]);
    console.debug(formatedMessage);
  }

  /**
   * Logs a consistent info message format to the console.
   * @param [message] Optional message to log. Accepts objects too. Avoid circular json object references!
   * @param [devOnly] Only logs in development if true.
   */
  public info(
    module: string,
    method: string,
    message?: any,
    devOnly?: boolean
  ) {
    // tslint:disable-next-line
    const formatedMessage = this.getMessage(module, method, message, devOnly);
    const timestamp = Date.now();
    this.keepLog([`Info: ${formatedMessage}; `]);
    console.info(formatedMessage);
  }

  /**
   * Logs a consistent warning message format to the console.
   * @param [message] Optional message to log. Accepts objects too. Avoid circular json object references!
   * @param [devOnly] Only logs in development if true.
   */
  public warn(
    module: string,
    method: string,
    message?: any,
    devOnly?: boolean
  ) {
    const formatedMessage = this.getMessage(module, method, message, devOnly);
    const timestamp = Date.now();
    this.keepLog([`Warning: ${formatedMessage}; ` ]);
    console.warn(formatedMessage);
  }

  /**
   * Logs a consistent error message format to the console.
   * @param [message] Optional message to log. Accepts objects too. Avoid circular json object references!
   * @param [devOnly] Only logs in development if true.
   */
  public error(
    module: string,
    method: string,
    message?: any,
    devOnly?: boolean
  ) {
    const formatedMessage = this.getMessage(module, method, message, devOnly);
    const timestamp = Date.now();
    this.keepLog([`Error: ${formatedMessage}; `]);
    console.error(formatedMessage);
  }

  /**
   * Logs a consistent log message format to the console in development only.
   * @param [message] Optional message to log. Accepts objects too. Avoid circular json object references!
   */
  public devOnly(module: string, method: string, message?: any) {
    if (!environment.production) {
      // tslint:disable-next-line
      console.log(this.getMessage(module, method, message));
    }
  }

  /**
   * Logs a consistent warning message to the console in development only.
   * @param [message] Optional message to log. Accepts objects too. Avoid circular json object references!
   */
  public techDebt(module: string, method: string, message?: any) {
    if (!environment.production) {
      // tslint:disable-next-line
      console.warn(
        `TECHDEBT: ${this.getMessage(module, method, message, false)}`
      );
    }
  }

  private getMessage(
    module: string,
    method: string,
    message?: any,
    devOnly?: boolean
  ) {
    const type = typeof message;
    if (
      (devOnly && environment.production) ||
      type === 'undefined' ||
      (type === 'string' && message.length === 0)
    ) {
      return `${module}.${method}`;
    } else if (type === 'string' || type === 'number') {
      return `${module}.${method} - ${message}`;
    } else {
      return `${module}.${method} - ${JSON.stringify(message)}`;
    }
  }

  private loadLogs() {
    const logsJson = localStorage.getItem('logs');
    if (logsJson) {
      this.logs = JSON.parse(logsJson);
    }

    this.cleanOldLogs();
  }

  private keepLog(args: (string | number)[]) {
    const logMessage = args.join(' ');
    const timestamp = Date.now();
    this.logs.push({ message: logMessage, timestamp });
    localStorage.setItem('logs', JSON.stringify(this.logs));
    this.cleanOldLogs();
  }

  private cleanOldLogs() {
    const tenHoursAgo = Date.now() - (10 * 60 * 60 * 1000); // 10 часов назад
    while (this.logs.length > 0 && this.logs[0].timestamp < tenHoursAgo) {
      this.logs.shift();
    }
  }
}
