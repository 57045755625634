export interface ClientDB {
  id?: number;
  identifier: string;
  code: number | null;
  rfid_tag: string | null;
  fueling_limit: number;
  driver_vehicle: string | null;
  uuid?: string | null;
  created?: number;
  updated?: number;
}


export interface ImportClient {
  Code: number;
  'Driver / Vehicle': string;
  'RFID tag': string;
  'Fueling Limit Each Time': number;
  Identifier: string;
}

export enum searchClientTypesEnum {
  'code' = 'code',
  'rfid_tag' = 'rfid_tag'
}

// export type searchClientTypes = 'CODE' | 'RFID';
export enum searchClientTypes {
  CODE = 'CODE',
  RFID = 'RFID'
}

export interface SearchClientParams {
  type?: searchClientTypesEnum | null;
  search?: string | null;
}

export const testClientsData: ClientDB[] = [
  {
    identifier: 'TestDriver',
    code: 1234,
    rfid_tag: null,
    fueling_limit: 9999,
    driver_vehicle: 'Driver'
  },
  {
    identifier: 'TestVehicle',
    code: 5678,
    rfid_tag: null,
    fueling_limit: 9999,
    driver_vehicle: 'Vehicle'
  }
];
