import { capSQLiteVersionUpgrade } from '@capacitor-community/sqlite/src/definitions';

export const dbVersionUpgrades: capSQLiteVersionUpgrade[] = [
  {
    toVersion: 1,
    statements: [
      //@formatter:off
      `
        CREATE TABLE IF NOT EXISTS clients (
          id INTEGER PRIMARY KEY NOT NULL,
          identifier TEXT NOT NULL,
          code NUMBER DEFAULT NULL,
          rfid_tag TEXT DEFAULT NULL,
          fueling_limit NUMBER NOT NULL,
          driver_vehicle TEXT NOT NULL,
          CONSTRAINT code_unique UNIQUE (code),
          CONSTRAINT rfid_tag_unique UNIQUE (rfid_tag)
        );
      `,
      `
       CREATE TABLE IF NOT EXISTS transactions (
          id INTEGER PRIMARY KEY AUTOINCREMENT,
          client TEXT DEFAULT NULL,
          odometer TEXT DEFAULT NULL,
          pump INTEGER DEFAULT NULL,
          nozzle INTEGER DEFAULT NULL,
          fuelGrade TEXT DEFAULT NULL,
          transactionId INTEGER DEFAULT NULL,
          transactionState TEXT DEFAULT NULL,
          transactionErrorMessage TEXT DEFAULT NULL,
          filledVolume REAL DEFAULT NULL,
          filledVolumeNoChangeCount INTEGER DEFAULT 0,
          requestPumpAuthorize TEXT DEFAULT NULL,
          responsePumpAuthorize TEXT DEFAULT NULL,
          transactionInformation TEXT DEFAULT NULL,
          isTracking INTEGER DEFAULT 1,
          isRequestPumpStop INTEGER DEFAULT 0,
          created INTEGER DEFAULT NULL,
          updated INTEGER DEFAULT NULL
        );
      `
    ]
  },
  {
    toVersion: 2,
    statements: [
      //@formatter:off
      `ALTER TABLE clients ADD COLUMN created INTEGER DEFAULT NULL;`,
      `ALTER TABLE clients ADD COLUMN updated INTEGER DEFAULT NULL;`,
      `ALTER TABLE transactions RENAME COLUMN client TO driver;`,
      `ALTER TABLE transactions ADD COLUMN vehicle TEXT DEFAULT NULL;`
    ]
  },
  {
    toVersion: 3,
    statements: [
      //@formatter:off
      `ALTER TABLE clients ADD COLUMN uuid TEXT DEFAULT NULL;`,
      `ALTER TABLE transactions ADD COLUMN siteName TEXT DEFAULT NULL;`
    ]
  },
  {
    toVersion: 4,
    statements: [
      //@formatter:off
      `ALTER TABLE transactions ADD COLUMN uuid TEXT DEFAULT NULL;`,
      `ALTER TABLE transactions ADD COLUMN sync_api INTEGER DEFAULT 0;`
    ]
  }
];
