import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { AppStore } from '@core/store/app.store';

function guestGuard({
  redirectTo,
  isGuestMode = true
}: {
  isGuestMode?: boolean;
  redirectTo?: any[];
} = {}): CanMatchFn {
  return async () => {
    const appStore = inject(AppStore);
    const router = inject(Router);

    const isGuestModeState = appStore.getState('isGuestMode');

    if (isGuestMode !== isGuestModeState) {
      return router.createUrlTree(
        redirectTo ?? [isGuestModeState ? 'welcome' : 'settings']
      );
    }

    return isGuestModeState === isGuestMode;
  };
}

export const isNoGuestMode = () => {
  return guestGuard({
    isGuestMode: false
  });
};
export const isGuestMode = () => {
  return guestGuard({
    isGuestMode: true
  });
};
