import { inject, Injectable } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppStore } from '@core/store/app.store';
import { MD5 } from 'crypto-js';
import { API_PTS_CONTEXT } from '@core/services/PTSClient/auth/context.auth';

@Injectable({
  providedIn: 'root'
})
export class PTSDigestAuthInterceptor implements HttpInterceptor {
  readonly appStore = inject(AppStore);
  readonly http = inject(HttpClient);
  headers: { [prop: string]: string } = {};

  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const PTSSettings = this.appStore.state().PTSSettings;
    const context = request.context.get(API_PTS_CONTEXT);

    if (context && context.auth) {
      const headersConfig = this.headers;
      if (!request.headers.has('Content-Type')) {
        headersConfig['Content-Type'] = 'application/json';
      }

      if (context && context.auth) {
        const username = context.auth.username || '';
        const password = context.auth.password || '';
        const realm = 'Pts2WebServer';
        const qop = 'auth';
        const digestURI = '/jsonPTS';

        // Generate a random nonce
        const nonce = Math.random().toString(36).substring(2);

        // Generate the cnonce value
        const cnonce = Math.random().toString(36).substring(2);

        // Generate the nc value
        const nc = '00000001'; // Increment this value for subsequent requests

        const HA1 = MD5(`${username}:${realm}:${password}`).toString();
        const HA2 = MD5(`${request.method}:${digestURI}`).toString();
        // const response = MD5(`${HA1}:${nonce}:${HA2}`).toString();
        const response = MD5(
          `${HA1}:${nonce}:${nc}:${cnonce}:${qop}:${HA2}`
        ).toString();

        // Generate the Authorization header

        const authHeader = [
          `Digest username="${username}"`,
          `realm="${realm}"`,
          `nonce="${nonce}"`,
          `uri="${digestURI}"`,
          `response="${response}"`,
          `qop=${qop}`,
          // `opaque="${opaque}"`,
          //`algorithm="MD5"`,
          `nc="${nc}"`,
          `cnonce="${cnonce}"`
        ].join(', ');

        const authenticatedRequest = request.clone({
          setHeaders: {
            Authorization: authHeader
          }
        });
        return next.handle(authenticatedRequest);
      }

      return next.handle(request);
    }

    return next.handle(request);
  }
}

export const PTS_DIGEST_INTERCEPTOR = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: PTSDigestAuthInterceptor,
    multi: true
  }
];
