import config from '@capacitor-config';

export const CONFIG_APP = {
  PACKAGE_NAME: config.appId || 'com.incust.primeoffline',
  DEFAULT_LANGUAGE: 'en',
  SUPPORTS_LANGUAGES: {
    en: 'English',
    uk: 'Українська',
    ru: 'Русский',
    pl: 'Polski',
    sk: 'Slovak',
    az: 'Azərbaycan dili'
  },
  DOWNTIME_CLIENT_SECONDS: 30, // время в секундах для таймера бездействия
  COUNTDOWN_CLIENT_SECONDS: 15, // время в секундах для модалки таймера,
  DOWNTIME_IN_TRANSACTION_SECONDS: 30, // время в секундах для таймера бездействия,
  PUMP_AUTO_STOP_MINUTES: 2, // время в минутах для авто остановки насоса,
  PTS_TIMEOUT: 30000 // время в миллисекундах для таймаута запроса к PTS
};
